<template>
	<div class="back_box">
		<h1 style="text-align: center;line-height: 40px;font-size: 20px;">资讯列表</h1>
		
		
		
		<div@click='zhifu(1)'>
			<el-row style="width:70%; margin: 0 auto; cursor: pointer; margin-top: 20px;">
				<el-col :span="2" style="background: #ccc;margin-right: 5px;">
					<p style="text-align: center;color: #fff;line-height: 30px;">03</p>
					<p style="text-align: center;color: #fff;line-height: 30px;">2021-09</p>
				</el-col>
				<el-col :span="20">
					<p style="margin-bottom: 10px;">
						<span style="font-size: 20px;">看金华职业技术学院如何应对疫情期间顶岗实习？</span><span style="color: red;">【付费2元阅读全文】</span>
					</p>
					<p style="font-size: 12px;">睿格致响应国家“停课不停学”的号召，为全国建筑类专业高校提供免费在线“停课不停学”专项服务以来，持续组织了多场线上培训课程，保障各院校在疫情期间顺利实施网络教学、网络实训计划，截止目前累计申报院校共450所院校，近1000余教师</p>
				</el-col>
			</el-row>
		</div>
		<div@click='zhifu(1)'>
			<el-row style="width:70%; margin: 0 auto; cursor: pointer; margin-top: 20px;">
				<el-col :span="2" style="background: #ccc;margin-right: 5px;">
					<p style="text-align: center;color: #fff;line-height: 30px;">04</p>
					<p style="text-align: center;color: #fff;line-height: 30px;">2021-09</p>
				</el-col>
				<el-col :span="20">
					<p style="margin-bottom: 10px;">
						<span style="font-size: 20px;">荣耀时刻 | 京津冀BIM极限挑战赛精彩回顾~</span><span style="color: red;">【付费2元阅读全文】</span>
					</p>
					<p style="font-size: 12px;">随着建筑行业 BIM 技术的应用迅速发展，业内对于 BIM 人才的需求愈发迫切。同时关于“1+X”首批证书制度试点工作已启动，其中就包含建筑信息模型 （BIM）职业技能等级证书，为推动新时代教育改革政策，推动 BIM、VR 及虚拟仿 </p>
				</el-col>
			</el-row>
		</div>
		<div@click='zhifu(1)'>
			<el-row style="width: 70%; margin: 0 auto; cursor: pointer; margin-top: 20px;">
				<el-col :span="2" style="background: #ccc;margin-right: 5px;">
					<p style="text-align: center;color: #fff;line-height: 30px;">05</p>
					<p style="text-align: center;color: #fff;line-height: 30px;">2021-09</p>
				</el-col>
				<el-col :span="20">
					<p style="margin-bottom: 10px;">
						<span style="font-size: 20px;">【创新归来】2020年吉林省职业院校技能大赛完美收官</span><span style="color: red;">【付费2元阅读全文】</span>
					</p>
					<p style="font-size: 12px;">为深入贯彻落实《国务院加快发展现代职业教育的决定》和《中共吉林省委吉林省人民政府关于加快发展吉林特色现代职业教育的实施意见》文件精神，不断深化教育教学改革共同推动高等职业教育创新发展，2020年吉林省职业院校技能大赛建设工程</p>
				</el-col>
			</el-row>
		</div>
		<div@click='zhifu(1)'>
			<el-row style="width: 70%; margin: 0 auto; cursor: pointer; margin-top: 20px;">
				<el-col :span="2" style="background: #ccc;margin-right: 5px;">
					<p style="text-align: center;color: #fff;line-height: 30px;">06</p>
					<p style="text-align: center;color: #fff;line-height: 30px;">2021-09</p>
				</el-col>
				<el-col :span="20">
					<p style="margin-bottom: 10px;">
						<span style="font-size: 20px;">教育部发布《教育部2020年部门预算》，高教领域相关预算为10,576,257.15万元</span><span style="color: red;">【付费2元阅读全文】</span>
					</p>
					<p style="font-size: 12px;">按照部门预算的原则，教育部所有收入和支出均纳入部门预算管理。收入包括：上年结转、财政拨款收入、事业收入、事业单位经营收入、其他收入、使用非财政拨款结余等;支出包括：一般公共服务支出、外交支出、教育支出、科学技术支出、</p>
				</el-col>
			</el-row>
		</div>
		<div@click='zhifu(1)'>
			<el-row style="width: 70%; margin: 0 auto; cursor: pointer; margin-top: 20px;">
				<el-col :span="2" style="background: #ccc;margin-right: 5px;">
					<p style="text-align: center;color: #fff;line-height: 30px;">07</p>
					<p style="text-align: center;color: #fff;line-height: 30px;">2021-09</p>
				</el-col>
				<el-col :span="20">
					<p style="margin-bottom: 10px;">
						<span style="font-size: 20px;">“活”用新科技，使用“智慧黑板”，打造“智慧课堂”123</span><span style="color: red;">【付费2元阅读全文】</span>
					</p>
					<p style="font-size: 12px;">     随着科技的发展，21世纪已经全面进入信息化时代。为了加快推进教育信息建设，全面提升信息化水平，提高教师正确使用现代化信息技术辅助教学能力。睿格致推出了建筑工程教学智慧黑板这一项目。</p>
				</el-col>
			</el-row>
		</div>
		<div@click='zhifu(1)'>
			<el-row style="width: 70%; margin: 0 auto; cursor: pointer; margin-top: 20px;">
				<el-col :span="2" style="background: #ccc;margin-right: 5px;">
					<p style="text-align: center;color: #fff;line-height: 30px;">08</p>
					<p style="text-align: center;color: #fff;line-height: 30px;">2021-09</p>
				</el-col>
				<el-col :span="20">
					<p style="margin-bottom: 10px;">
						<span style="font-size: 20px;">看金华职业技术学院如何应对疫情期间顶岗实习？</span><span style="color: red;">【付费2元阅读全文】</span>
					</p>
					<p style="font-size: 12px;">    睿格致响应国家“停课不停学”的号召，为全国建筑类专业高校提供免费在线“停课不停学”专项服务以来，持续组织了多场线上培训课程，保障各院校在疫情期间顺利实施网络教学、网络实训计划，截止目前累计申报院校共450所院校，近1000余教师。</p>
				</el-col>
			</el-row>
		</div>
		<div@click='zhifu(1)'>
			<el-row style="width: 70%; margin: 0 auto; cursor: pointer; margin-top: 20px;">
				<el-col :span="2" style="background: #ccc;margin-right: 5px;">
					<p style="text-align: center;color: #fff;line-height: 30px;">09</p>
					<p style="text-align: center;color: #fff;line-height: 30px;">2021-09</p>
				</el-col>
				<el-col :span="20">
					<p style="margin-bottom: 10px;">
						<span style="font-size: 20px;">BIMFILM3.0版本新功能展示</span><span style="color: red;">【付费2元阅读全文】</span>
					</p>
					<p style="font-size: 12px;">您好，这里是BIMFILM。我们BIMFILM3.0新版本已经上线好多天啦，不知道大家开始使用体验了没有，还没有开始尝试使用的朋友可以去我们的官网下载使用哟。这次给大家带来几项关于新功能如何使用的干货知识。希望大家认真阅读和学习，让自己在实际制作动画的过程中也更容易和更顺利。

</p>
				</el-col>
			</el-row>
		</div>
		
		
		<div@click='zhifu(1)'>
			<el-row style="width: 70%; margin: 0 auto; cursor: pointer; margin-top: 20px;">
				<el-col :span="2" style="background: #ccc;margin-right: 5px;">
					<p style="text-align: center;color: #fff;line-height: 30px;">10</p>
					<p style="text-align: center;color: #fff;line-height: 30px;">2021-09</p>
				</el-col>
				<el-col :span="20">
					<p style="margin-bottom: 10px;">
						<span style="font-size: 20px;">景区如何实现大面积网络覆盖</span><span style="color: red;">【付费2元阅读全文】</span>
					</p>
					<p style="font-size: 12px;">从技术上来讲，这个是不成问题的，至少有3条途经，我认为可行；1、跟三大运营商合作，由他们提供硬件和网络，对本区域进行WIFI覆盖；2、自己采购硬件，铺设网络进行WIFI覆盖，你需要的是设备室无线控制</p>
				</el-col>
			</el-row>
		</div>
		
		<div@click='zhifu(1)'>
			<el-row style="width: 70%; margin: 0 auto; cursor: pointer; margin-top: 20px;">
				<el-col :span="2" style="background: #ccc;margin-right: 5px;">
					<p style="text-align: center;color: #fff;line-height: 30px;">11</p>
					<p style="text-align: center;color: #fff;line-height: 30px;">2021-09</p>
				</el-col>
				<el-col :span="20">
					<p style="margin-bottom: 10px;">
						<span style="font-size: 20px;">“活”用新科技，使用“智慧黑板”，打造“智慧课堂”123</span><span style="color: red;">【付费2元阅读全文】</span>
					</p>
					<p style="font-size: 12px;"> 随着科技的发展，21世纪已经全面进入信息化时代。为了加快推进教育信息建设，全面提升信息化水平，提高教师正确使用现代化信息技术辅助教学能力。睿格致推出了建筑工程教学智慧黑板这一项目。</p>
				</el-col>
			</el-row>
		</div>
		<div@click='zhifu(1)'>
			<el-row style="width: 70%; margin: 0 auto; cursor: pointer; margin-top: 20px; margin-bottom: 20px;">
				<el-col :span="2" style="background: #ccc;margin-right: 5px;">
					<p style="text-align: center;color: #fff;line-height: 30px;">12</p>
					<p style="text-align: center;color: #fff;line-height: 30px;">2021-09</p>
				</el-col>
				<el-col :span="20">
					<p style="margin-bottom: 10px;">
						<span style="font-size: 20px;">北京工业职业技术学院教师教学能力提升培训圆满完成！</span><span style="color: red;">【付费2元阅读全文】</span>
					</p>
					<p style="font-size: 12px;">2019年北京工业职业技术学院全息交互实训室已经正式建成，该实训室结合虚拟仿真技术、全息技术、VR技术带学生“走进”一个全新的学习的环境中，去身临其境地接触现实难以实现的学习场景。</p>
				</el-col>
			</el-row>
		</div>
		<!-- <div style="margin: 0 auto; display: block;width: 450px;margin-bottom: 295px;margin-top: 100px;">
			<el-button @click='zhifu(0)'>点我支付宝付费两元阅读全文</el-button>
			<el-button @click='zhifu(1)'>点我微信付费两元阅读全文</el-button>
		</div> -->
		<el-dialog title="微信支付" :visible.sync="dialogVisible_zhifu" width="20%" :close-on-click-modal="false"
			@close='close_zhifu_title'>
			<div style="width: 100%;text-align: center;">
				<vue-qr :text="zhifu_img" :size="200"></vue-qr><br>
				<img src="../assets/weixin.png" >
				<p style="line-height: 20px;">付费两元即可阅读全文</p>
			</div>
			<span slot="footer" class="dialog-footer"></span>
		</el-dialog>
	</div>
</template>
<script>
	import vueQr from 'vue-qr'
	import {
		requestesalipay,
		requestcheck_check_orderstate,
		requesteswxpay,
		requestLogin
	} from "../api/request";
	export default {
		data() {
			return {
				activeIndex2: '1',
				dialogVisible_zhifu: false,
				productType: ['50', '100', '300', '500', '1000'],
				productPay: [{
					'name': "",
					'urlImg': require('../assets/pay.png'),
				}, {
					'name': "",
					'urlImg': require('../assets/weixin.png'),
				}],
				zhifu_img: "",
				setmimi: null
			};
		},
		components: {
			vueQr
		},
		//函数方法
		methods: {

			close_zhifu_title() {
				clearTimeout(this.setmimi);
			},
			zhifu(index) {
				let _this = this;

				this.dialogVisible_zhifu = true;
				if (this.Account.GetToken() == null) {
					let obj = Object();
					obj.account = 'xxstu';
					obj.password = this.$md5('xxstu');
					requestLogin(obj).then(res => {
						if (res.code === 0) {
							// localStorage.setItem("token","f225fe8c1a4fc360153bdc390c383425");
							localStorage.setItem("token", res.ret.token);
							this.zhifu_fn(index)
						}
					})

				} else {
					this.zhifu_fn(index)
				}





			},
			zhifu_fn(index) {
				let _this = this;
				let info = {
					total_amount: 2,
					subject: "支付购买金币",
				}
				if (index == 0) {

					this.zhifu_title = '支付宝扫描支付';
					requestesalipay(info).then(res => {
						_this.zhifu_img = res.ret.qrcode_url;
						this.setmimi = setInterval(function() {
							console.log('-1---------------------1-')
							let info1 = {
								out_trade_no: res.ret.out_trade_no,
								total_amount: 2,
								subject: "支付购买金币",
							}
							requestcheck_check_orderstate(info1).then(res => {
								if (res.code == 0) {
									_this.$message({
										message: '恭喜你支付成功',
										type: 'success'
									});
									localStorage.setItem("zhifu", 0);
									localStorage.removeItem("token");
									_this.dialogVisible_zhifu = false;
									_this.$router.push("/MyWallet")
									clearTimeout(_this.setmimi);
								}
							})
						}, 1000)
					})
				} else if (index == 1) {
					this.zhifu_title = '微信扫描支付';
					requesteswxpay(info).then(res => {
						_this.zhifu_img = res.ret.qrcode_url;
						this.setmimi = setInterval(function() {
							console.log('----------------------2-')
							let info1 = {
								out_trade_no: res.ret.out_trade_no,
								total_amount: 2,
								subject: "支付购买金币",
							}
							requestcheck_check_orderstate(info1).then(res => {
								if (res.code == 0) {
									_this.$message({
										message: '恭喜你支付成功',
										type: 'success'
									});
									localStorage.setItem("zhifu", 0);
									localStorage.removeItem("token");
									_this.dialogVisible_zhifu = false;
									_this.$router.push("/MyWallet")
									clearTimeout(_this.setmimi);
								}
							})
						}, 1000)
					})
				}
			}
		},
		mounted() {},
		destroyed() {


		}
	};
</script>
<style scoped>
	@import "../style/common.css";
	/* .back_box{
	background: url(../assets/img/register/bg3.jpg) no-repeat top center;
	background-size: 100%;
	height: 100vh;
} */
</style>
